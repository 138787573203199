document.addEventListener('turbolinks:load', () => {
    const notifications = document.querySelectorAll('.notification');

    notifications.forEach(notification => {
        // Fade in
        setTimeout(() => {
            notification.style.opacity = '1';
            notification.style.transform = 'translateX(0)';
        }, 100);

        // Auto-hide after 5 seconds
        setTimeout(() => {
            hideNotification(notification);
        }, 5000);

        // Close button functionality
        const closeButton = document.createElement('button');
        closeButton.innerHTML = '&times;';
        closeButton.className = 'absolute top-0 right-0 px-2 py-1 text-gray-500 hover:text-gray-700 focus:outline-none';
        closeButton.addEventListener('click', () => hideNotification(notification));
        notification.appendChild(closeButton);
    });
});

function hideNotification(notification) {
    notification.style.opacity = '0';
    notification.style.transform = 'translateX(100%)';
    setTimeout(() => {
        notification.remove();
    }, 300);
}